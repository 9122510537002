<template>
      <v-card
        elevation="2"
        width="100%"
        class="my-5 px-4"
        :loading="loading"
      >
        <v-container >

          <v-row  >
            <v-card-title class="my-1 text-h5 text--primary">ナビゲーション表示回数</v-card-title>
          </v-row>

          <v-row >
            <v-col cols=12 sm=12 md=6 lg=6 xl=6>
              <p :class="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)?'':' ml-10 text-h6  text--primary'" >{{chartItem.dateText}}の表示回数:{{chartItem.viewCnt}}回</p>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols=12 sm=12 md=6 lg=6 xl=6>
              <v-row :class="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)?'':'pt-2 ml-10'" :justify="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? 'start': 'end'">
                <v-col cols=8 sm=8 md=6 lg=6 xl=6>
              <div class="mt-0 mr-2 pa-0" >
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  color="narekomu"
                  outlined
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-form  ref="form" v-model="valid" lazy-validation  @submit.prevent>
                    <v-text-field
                      v-model="dateText"
                      label="確認したい月を入力"
                      persistent-hint
                      dense
                      v-bind="attrs"
                      color="narekomu"
                      outlined
                      :rules="rules"
                      v-on="on"
                      class="pa-0 ma-0"
                    ></v-text-field>
                    </v-form>
                  </template>
                  <v-date-picker
                    v-model="date"
                    type="month"
                    color="narekomu"
                    no-title
                    locale="ja"
                    @input="formatDate(date)"
                  ></v-date-picker>
                </v-menu>
              </div>
                </v-col>
                <v-col cols=3 sm=3 md=6 lg=6 xl=6>
              <v-btn
                color="narekomu"
                class="white--text mt-0 pb-0 mr-8 "
                :disabled="!valid"
                @click="getData()"
              >更新</v-btn>
               </v-col>
              </v-row>
           
            </v-col>

          </v-row>
            </v-container>
            <div :style="$vuetify.breakpoint.smAndDown ?'  -ms-writing-mode: tb-rl; writing-mode: vertical-rl; width:100%; overflow-x:auto;': 'overflow-x:auto;'"  >
              <TopChart 
                id="v-step-2" 
                :style="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs )?'width:1000px; min-width:1000px:':''"
                :toggleChart="toggleChart"
                :dataChart="chartItem.dataChart"
                :labels="chartItem.labels"
                :maxCount="chartItem.maxCount"
                :stepSize="chartItem.stepSize"
                :height="60"
              />
          </div>


      
      </v-card>
       
</template>

<script>

const TopChart = () => import('@/components/TopChart.vue');
// import axios from "axios";

  export default {
    name: 'CardTopChart',
    // mixins:[globalMixin],
    components:{ TopChart },
    props:["chartItem"],
    data: () => ({
        valid:true,
        menu1: false,
        dateText:"",
        date: "2022-06",
        toggleChart:false,
        loading:true,
        rules:[
            v => !!v || "必須項目です。例:2022-01 ",
            v => (v.split("-").length==2) || "例:2022-01 ",
            v => (v.split("-")[0].length==4) || "例:2022-01 ",
            v => (v.split("-")[1] <= 12) || "例:2022-01 ",
            v => (v.length==7) || "例:2022-01 ",
        ]
    }),

    watch:{
      chartItem: {
        handler(){
          this.loading = false;
          this.reChart();
        },
        deep: true,
      },
    },


    mounted(){
      this.getNowDate();
    },

    methods: {

    
      getData: async function () {
        this.loading = true;
        this.$emit('get-data-emit',this.dateText);
      },

      //日付データを整形する
      formatDate (date) {
        if (!date) return null;
        const [year, month] = date.split("-");
        this.dateText = `${year}-${month}`;
        this.menu1 = false;
        this.valid = true;
      },

      shapeMonth(month){
        if(month >= 10) return `${month}`
        else return `0${month}`
      },

      getNowDate(){
        let now = new Date();
        this.date = `${now.getFullYear()}-${this.shapeMonth(now.getMonth()+1)}`;
        this.dateText = this.date;
      },

      //グラフ再描写のため
      reChart() {        
          this.toggleChart = !this.toggleChart;
      },

      stopLoad(){
        this.loading= false;
      }
      
  
    },

  



  }
</script>


<style lang="scss" >
  @import "@/assets/main.scss";

  .Title > h1 {
    color: gray;
    font-weight: 200;
    border-bottom: 1px solid gray;
  }

</style>

