<template>
  <v-container class="mt-5 main-content" max-width="2000px">
    <div class="Title" >
      <h1 id="v-step-0" class="text-h5">  トップページ 
        <v-btn icon @click="slide=true;"><v-icon>{{icons.informationOutline}}</v-icon></v-btn>
         </h1>
    </div>
    <div class="d-flex flex-column justify-center mx-0 "  >
    <v-alert v-for="item in systemNotification" :key="item.id"
      class="my-1 py-0"
      border="left"
      colored-border
      :type="item.type"
      elevation="2"
      prominent
    >
        <v-list-item-content class="black--text ma-0 mb-0">
          <v-list-item-subtitle class="ma-0 mb-0 grey--text "  style="white-space:normal;">{{item.title}}</v-list-item-subtitle>
          <v-col v-html="item.text"></v-col>
          </v-list-item-content> 
    </v-alert>
      <CardStorage v-if="false" />
      <CardStorageBar  />
      <CardTopChart
        ref="childchart"
        v-on:sendPopularList="sendPopularList"
        v-on:get-data-emit="getData"
        :chartItem="chartItem"
      />
      <CardPopularNavi
        id="v-step-3"
        ref="child"
        :popularList="popularList"
        :toggle="toggle"
      /> 
    </div>

    <v-dialog 
      v-model="slide"
      max-width="400px" 
      width="90%"
    >
      <CarouselTutorial
        type="top"
        v-on:closeEmit="slide=false"
      />
      <!-- <v-btn   color="blue" class="white--text mt-1"  @click="slide=false;">Close</v-btn> -->
    </v-dialog>

        <v-dialog
          v-model="$store.state.user_model.topLoading"
          persistent
          max-width="220"
        >
        
          <v-card 
            color="primary"
            elevation=0
            class="text-center "
            width="220px"
            height="220px"
          > 
            <v-progress-circular
              :indeterminate="true"
              persistent
              :size="150"
              width=10
              color="white"
              class="mt-6 "
            >Loading...
            </v-progress-circular>
          </v-card>
        </v-dialog>
  
  </v-container>
  
</template>

<script>
  const CardPopularNavi = () => import('@/components/CardPopularNavi.vue');
  const CardStorage = () => import('@/components/CardStorage.vue');
  const CardStorageBar = () => import('@/components/CardStorageBar.vue');
  // const CardTopChart = () => import('@/components/CardTopChart.vue');
  const CarouselTutorial = () => import('@/components/CarouselTutorial.vue');

  import axios from "axios";
  import { mdiInformationOutline } from '@mdi/js'

  import CardTopChart from '@/components/CardTopChart.vue';

  export default {
      name: 'Home',
      components: { CardStorage ,CardTopChart, CardPopularNavi, CarouselTutorial, CardStorageBar},
      data () {
        return {
          icons:{
            "informationOutline":mdiInformationOutline
          },
          popularList:[],
          loadedList:[],
          loading:true,
          types:["navi","object","image","pdf","sound","movie","url"],
          toggle:false,
          slide:false,
          dateText:"",
          date:"",
          systemNotification:{},
          isNotification:false,
          chartItem:{
            dataChart:[],
            labels:[],
            maxCount:1,
            stepSize:1,
            dateText:"",
            viewCnt:0,
          }
        }
      },

      watch:{

        loadedList:{
          handler(){
            if(this.loadedList.length == this.types.length){
                 this.$store.state.user_model.topLoading =  false;
                let now = new Date();
                 this.getData(`${now.getFullYear()}-${this.shapeMonth(now.getMonth()+1)}`);
            }
          },
          deep: true,
        }

      },


      methods:{
        sendPopularList(array){
          this.popularList = array;
          this.$refs.child.make(array)
        },

        shapeMonth(month){
          if(month >= 10) return `${month}`
          else return `0${month}`
        },

        getNotification(){
          axios({
                method: "GET",
                url:`${process.env.VUE_APP_URL}notification`,
                params: {"document":"portalSettings"},
                headers: {"X-Api-Key": process.env.VUE_APP_KEY,"Content-Type":"application/json"},
                
            }).then(res => {
                let systemNotification = res.data;
                for(let key  in systemNotification){
                  if(systemNotification[key].status){ 
                    this.isNotification = true;
                    this.systemNotification[key] = systemNotification[key];
                  }

                }
                
            }).catch((err) => {
              
              console.log(err)
          })
        },

        getNowDate(){
          let now = new Date();
          this.date = `${now.getFullYear()}-${this.shapeMonth(now.getMonth()+1)}`;
          this.dateText = this.date;
          // this.dateShow = this.dateText;
        },

        checkDate(text){
          let now = new Date();
          if(`${now.getFullYear()}-${this.shapeMonth(now.getMonth()+1)}`!=text){
            return text
          }
          return ""
        },


        // チャートデータの生成 
        makeData(data,dateText){
          let yd = dateText.split("-"); //[year,month]
          let today =(new Date().getMonth()+1 == yd[1] )? new Date().getDate() : 0;
          let span = 0
          let cnt =  (yd[0] ==  new Date().getFullYear() & yd[1] == new Date().getMonth()+1 ) ? new Date().getDate() : new Date(yd[0], yd[1], 0).getDate() ;
          let span_tmp = Math.max(cnt/6,1);
          this.chartItem.dataChart = [...Array(cnt)].map(() => 0);
          this.chartItem.labels = [...Array(cnt)].map(() => "");
          this.chartItem.viewCnt = data.length;
          for(let i = 0; i < data.length; i++ ){
            let t = new Date(data[i].Timestamp.substr(0,10));
            this.chartItem.dataChart[t.getDate()-1]+=1;
          }
          for(let i = 1; i < cnt; i++){
            if(i == 1 || i == today || i == cnt || span <= i  ){ 
              this.chartItem.labels[i-1] = yd[1]+"/"+(i);
              span += span_tmp;
            }
          }
          // Set up Chart Option
          this.chartItem.maxCount = this.chartItem.dataChart.reduce((a,b)=>Math.max(a,b),0);
          this.chartItem.maxCount = Math.max(Math.ceil(this.chartItem.maxCount/3)*3,1);
          this.chartItem.stepSize = Math.max(this.chartItem.maxCount/3,1);
          this.chartItem.dateText = dateText;
    
        },

        //指定された日付での人気のナビリストを作成
        makePopularList(data){
          let naviList = {}
          for(let i = 0 ; i < data.length; i++){
            if(data[i].NaviId in naviList){
              naviList[data[i].NaviId] += 1;
            }else{
              naviList[data[i].NaviId] = 1;
            }
          }
          let array = Object.keys(naviList).map((k)=>({ key: k, value: naviList[k] }));
          array.sort((a, b) => b.value - a.value);
          this.popularList = array;
        },

        resetData(){
          let yd = this.chartItem.dateText.split("-"); //[year,month]
          let cnt =  new Date(yd[0], yd[1], 0).getDate();
          if( isNaN(cnt) ) cnt = 0; 
          this.chartItem.dataChart = [...Array(cnt)].map(() => 0);
          this.chartItem.labels = [...Array(cnt)].map(() => "");
          this.chartItem.viewCnt = 0;
        },

        getData: async function (dateText) {    
          await axios({
            method: "GET",
            url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/navis/pv`,
            headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId,"Content-Type":"application/json" },
            params:{"date":this.checkDate(dateText)}
          },).then((response) => {
            this.makeData(response.data.results,dateText);
            this.makePopularList(response.data.results);
          }).catch(() => {
            this.resetData();
            this.makePopularList([]);
          });
          this.chartItem.dateText = dateText;
        },
        autoLink(text) {
            return  text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1'>$1</a>");
        },
      },

      created(){
        this.getNotification();
        //ログイン時にコンテンツデータ取得
        if( !this.$store.state.user_model.content ){
          this.$store.state.user_model.topLoading = true;
          for(let i = 0 ; i < this.types.length; i++){
            this.$store.dispatch("setContentInfo",[this.types[i],this.$store.state.auth.login])
            .then((type)=>{
              this.loadedList.push(type);
            })
          }   
        }else{
          let now = new Date();
          this.getData(`${now.getFullYear()}-${this.shapeMonth(now.getMonth()+1)}`);
          this.loading = true;
        }
      }


      
  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 400;
    border-bottom: 1px solid gray;
  }

</style>

